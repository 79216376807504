// Default theme colors
$theme-colors: (
  coolblack: #090a0b,
  spacegrey: #353535,
  snowwhite: #ffffff,
);

$theme-name: "";
$theme-color: map-get($theme-colors, "snowwhite");

@if map-has-key($theme-colors, $theme-name) {
  $theme-color: map-get($theme-colors, $theme-name);
} @else if str-index($theme-name, "#") == 1 {
  $theme-color: #ffffff;
}

@import "yat";
